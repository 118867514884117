@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Display:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@font-face {
  font-family: "val stencil";
  src: local("val stencil"),
    url("/static/media/val stencil.a9451f70.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Adoria";
  src: local("Adoria"),
    url(/static/media/Adoria.0678dbcf.otf) format("truetype");
  font-weight: normal;
}

:root {
  --font-family: 'Manrope', sans-serif, 'Big Shoulders Stencil Display','Roboto','Raleway','Adoria','val_stencil';
  
  

  --gradient-text: linear-gradient(89.97deg, #ff4d4d 1.84%, #ff4d4d 102.67%);
  --gradient-text2: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);

  --color-bg: radial-gradient(circle, rgba(0,3,17,1) 0%, rgba(69,0,111,1) 45%, rgba(0,3,17,1) 100%);
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: black;
  --color-subtext: #FF8A71;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
  height: 100%;
}

a {
  color: unset;
  text-decoration: none;
}

.background_dm {
  background: rgb(51,51,78)
}

.gradient__bg {

  /* ff 3.6+ */

  /* safari 5.1+,chrome 10+ */

  /* opera 11.10+ */

  /* ie 10+ */

  /* global 92%+ browsers support */
  background:radial-gradient(circle at 3% 25%, rgba(255, 190, 0, 1) 0%, rgba(255, 190, 0, 1) 16%, rgba(187, 255, 249, 1) 60%, rgba(238, 130, 238, 1) 100%);

}



.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }  
}
.nft__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.nft__navbar-links {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.nft__navbar-links_logo {
    margin-right: 2rem;
}

.nft__navbar-links_logo img {
    width: 62.56px;
    height: 16.02px;
}

.nft__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.nft__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nft__navbar-links_container p,
.nft__navbar-sign p,
.nft__navbar-menu_container p {
    color: black;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.nft__navbar-sign button,
.nft__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #FF4820;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.nft__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.nft__navbar-menu svg {
    cursor: pointer;
}

.nft__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.nft__navbar-menu_container p {
    margin: 1rem 0;
}

.nft__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .nft__navbar-links_container {
        display: none;
    }

    .nft__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .nft__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .nft__navbar {
        padding: 2rem;
    }

    .nft__navbar-sign {
        display: none;
    }

    .nft__navbar-menu_container {
        top: 20px;
    }

    .nft__navbar-menu_container-links-sign {
        display: block;
    }
}

.flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: #bbb;
    color: black;
  }
  
  .flip-card-back {
    background-color: #2980b9;
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
.flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: #bbb;
    color: black;
  }
  
  .flip-card-back {
    background-color: #2980b9;
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
